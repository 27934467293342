.not-found-page {
	width: 100%;
	height: 100svh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	box-sizing: border-box;

	p {
		font-size: 1rem;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 5px;
	}

	small {
		text-align: center;
		font-size: 0.9rem;
		box-sizing: border-box;
	}
}
